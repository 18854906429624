import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import megasearch from '../../../images/megasearch.png';
import django from '../../../images/django.webp';
import AWS from '../../../images/AWS.webp';
import python from '../../../images/python.webp';
import megasearchscreen from '../../../images/megasearchscreen.png';
import megasearchlist from '../../../images/megasearchlist.png';
import macscreen from '../../../images/macscreen.png';
import javascript from '../../../images/javascript.png';
import livestreaming from '../../../images/livestreaming.png';
import stripe from '../../../images/stripe.png';
import { Api } from '../../../Api';
import Loader from "react-js-loader";

class CaseStudyDetail extends React.Component {
  // Constructor 
  constructor(props) {
    super(props);
      
    let path =  window.location.pathname;
    let pathArr = path.split("/");

    this.caseStudyUrl = pathArr;

      this.state = {
          caseStudy: {},
          DataisLoaded: false,
      };

      window.scrollTo({
          top: 0,
          behavior: 'instant',
      });
  }


  componentDidMount = () => {
      fetch(Api.CaseStudyDetail+'/'+this.caseStudyUrl[2])
          .then((res) => res.json())
          .then((json) => {

              console.log(json.case_study_details);
              this.setState({
                caseStudy: json.case_study_details,
                  DataisLoaded: true
              });

          })          
  }


  render() {
    const { DataisLoaded, caseStudy } = this.state;
    const list = []

    if (!DataisLoaded) {return <div className={"item"}>
    <Loader type="spinner-circle" bgColor={"rgb(30, 62, 91)"} title={"Loading..."} color={'rgb(30, 62, 91)'} size={100} />
    </div>;}
    else{
      return (
        <>
        <Box className="lightGrey" sx={{ py:7 }}>
            <Container maxWidth="xl">
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{mb:0, textAlign:'center', color:'primary.main', textTransform:'uppercase'}}>{caseStudy.subtitle}</Typography>
                  <Box sx={{textAlign:'center', mb:4}}><h4>{caseStudy.title}</h4></Box>
                </Grid>
                <Grid container items display="row" spacing={3} sx={{alignItems:'center', flexDirection:'row-reverse'}}>
                    <Grid item md={6} xs={12}><img src={Api.siteUrl + "images/case-studies/" + caseStudy.image} alt={caseStudy.img_alt_name} width="100%" /></Grid>
                    <Grid item md={6} xs={12}>
                      <div dangerouslySetInnerHTML={{ __html:caseStudy.content}} />
                    </Grid>
                  </Grid>
            </Container>
        </Box>
        <Box className="NavyColour" sx={{ py:3 }}>
          <Container maxWidth="xl">
              <Grid directions="row" spacing={3} container items sx={{textAlign:'center', alignItems:'center', justifyContent:'space-between'}}>
              {
              caseStudy.section_2_icons.map((sec2Icon, index) => (
                  <Grid item sm={4} md={2} xs={12}><img src={Api.siteUrl + "images/case-studies/" + sec2Icon} alt="" width="100" /></Grid>
                ))
              }
              </Grid>
          </Container>
        </Box>
        <Box className="lightGrey" sx={{ py:7 }}>
          {caseStudy.section_3_image && caseStudy.section_4_image ? ( //if both images are not null
            <Container maxWidth="xl">
                <Grid container items display="row" sx={{alignItems:'center'}} spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Box sx={{textAlign:'center'}}><img src={Api.siteUrl + "images/case-studies/" + caseStudy.section_3_image} alt={caseStudy.section_3_img_alt_name} width="80%" /></Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div dangerouslySetInnerHTML={{ __html:caseStudy.section_3_content}} />
                    </Grid>
                </Grid>
                <Grid container items display="row" sx={{alignItems:'center'}} spacing={3}>
                    <Grid item md={6} xs={12}>
                      <div dangerouslySetInnerHTML={{ __html:caseStudy.section_4_content}} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{textAlign:'center'}}><img src={Api.siteUrl + "images/case-studies/" + caseStudy.section_4_image} alt={caseStudy.section_4_img_alt_name} width="80%" /></Box>
                    </Grid>
                </Grid>
            </Container>
            )  : (
              <Container maxWidth="xl">
                <Grid container items display="row" spacing={3}>
                    <Grid item md={6} xs={12}>
                      <div dangerouslySetInnerHTML={{ __html:caseStudy.section_3_content}} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div dangerouslySetInnerHTML={{ __html:caseStudy.section_4_content}} />
                    </Grid>
                </Grid>
            </Container>
            )}
        </Box>
      
        
      </>
      )
    }
  }
}

export default CaseStudyDetail