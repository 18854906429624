import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import cloudModern from '../../../images/cloudModern.jpg';
import { Helmet } from 'react-helmet';
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { Api } from '../../../Api';
import Loader from "react-js-loader";

class News extends React.Component {

    // Constructor 
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            DataisLoaded: false,
        };
    }
    
    componentDidMount = () => {

        fetch(Api.blogsList)
            .then((res) => res.json())
            .then((json) => {

                console.log(json);
               
                this.setState({
                    data: json,
                    DataisLoaded: true,
                });
 
            })          
    }


    render() {
        const { DataisLoaded, data, props } = this.state;
        const list = [];
       
        if (!DataisLoaded) {return <div className={"item"}>
        <Loader type="spinner-circle" bgColor={"rgb(30, 62, 91)"} title={"Loading..."} color={'rgb(30, 62, 91)'} size={100} />
    </div>;}
        else{
        return (
            <>
                <Helmet>
                    <link href="https://python.dotsquares.com/whypython" rel="canonical"/>
                    <title>Python News - Pythonsquares</title>
                    <meta name="description" content="Python is the top programming language of the era. Know why Python is trusted by top companies? Its scope & hire the best Python experts for your next project." />
                </Helmet>
                <Box className="pythonDevelopment" sx={{ py:7}}>
                    <Container maxWidth="md">
                        <Typography variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}><strong>News</strong></Typography>
                        <div class="bar"></div>
                        <Typography variant="body1" sx={{textAlign:'center', fontWeight:500}}>The latest web industry press, technology innovations, events, and some helpful pointers from us</Typography>
                    </Container>
                </Box>

                <Box className="" sx={{ py:6 }}>
                    <Container maxWidth="xl" sx={{my:4}}>
                        <Grid container display="row" items spacing={3}>
                            {
                            data.blogs.map((blog) => (
                                <Grid item sm={12} md={3}>
                                    <Card sx={{minHeight: 220}}>
                                        <Box sx={{textAlign:'center', minHeight: 90}}><a onClick={() => this.props.navigate("/news/"+blog.slug)}><img src={Api.siteUrl + "posts_images/banner/" + blog.banner_image} alt="cloud-modernization-blog" width="100%"/></a></Box>
                                        <Box sx={{p:2}}>
                                            <Typography variant="p" component="p" sx={{fontWeight:600, mb:2}}>{blog.date}</Typography>
                                            <Typography variant="h6" className="NavyColourText blog-post-heading" component="h6" sx={{fontWeight:600}}><a className="NavyColourText" onClick={() => this.props.navigate("/news/"+blog.slug)}>{blog.title}</a></Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                                
                                ))
                            }
                        </Grid>
                    </Container>   
                </Box>
            </>
        )
        }
    }
}

export function NewsWithRouter(props){
    const navigate = useNavigate();
    return (<News navigate={navigate}></News>)
}

export default News;