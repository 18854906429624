import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const items = [
  <Box className="item" data-value="1" sx={{p:1}}>
      <Card sx={{ borderLeft:2, borderRadius:0, borderColor: 'secondary.main'}} variant='contained'>
        <CardContent style={{padding:'15px'}}>
          <Typography component="div" variant="body1" sx={{minHeight:170, fontWeight:'500'}}>I have worked with Dotsquares for a year now on an instant digital, escrow payment solution. Their team is exceptional and efficient in every manner. It's great to have technology partners who deliver ahead of timelines. I am pleased with their work and performance.</Typography>
          <Typography variant="h6" component="h6" sx={{mt:2}}><i>- Tom Squire</i></Typography>
          <Typography component="p" variant="body1"> Shieldpay</Typography>
        </CardContent>
      </Card>
  </Box>,
  <Box className="item" data-value="2" sx={{p:1}}>
      <Card sx={{borderLeft:2, borderRadius:0, borderColor: 'secondary.main' }} variant='contained'>
        <CardContent style={{padding:'15px'}}>
          <Typography component="div" variant="body1" sx={{minHeight:170, fontWeight:'500'}}>
                Came in contact with Dotsquares about a year ago and they've helped in expanding our
                ideas and opportunities. With a great line of communication and reliable custom web
                designs they're definitely the best among all the web developers I've ever known. Any
                design changes and tweaks are always on point.
            </Typography>
          <Typography variant="h6" component="h6" sx={{mt:2}}><i>- Clyde Cayetano</i></Typography>
          <Typography component="p" variant="body1"> Batmail</Typography>
        </CardContent>
      </Card>
  </Box>,
  <Box className="item" data-value="3" sx={{p:1}}>
    <Card sx={{  borderLeft:2, borderRadius:0, borderColor: 'secondary.main'}} variant='contained'>
      <CardContent style={{padding:'15px'}}>
        <Typography component="div" variant="body1" sx={{minHeight:170, fontWeight:'500'}}>
            I hired the Dotsquares team to develop a website and 2 applications and they were spot
            on! What impressed me the most is the balance between budget and time and how excellent
            the services they deliver are.</Typography>
        <Typography variant="h6" component="h6" sx={{mt:2}}><i>- Mark Osmond</i></Typography>
        <Typography component="p" variant="body1"> Startup</Typography>
      </CardContent>
    </Card>
  </Box>,
  
];

const GreatReviews = () => {
  return (
    <Box className=" GreatReviews" sx={{ py:10}}>
        <Container maxWidth="xl">
            <Typography variant="h4" component="h4" sx={{textTransform:'uppercase', mb:2, textAlign:'center'}}>Our <strong>Testimonials</strong></Typography>
            <div class="bar"></div>
            <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                autoPlay="true"
                infinite="true"
                animationDuration="4000"
            /> 
        </Container>    
    </Box>
  )
}

export default GreatReviews