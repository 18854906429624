import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import portfolio01 from '../../../images/portfolio01.jpg';
import portfolio03 from '../../../images/portfolio03.jpg';
import cleaner from '../../../images/cleaner.jpg';
import { Helmet } from 'react-helmet';

const Projects = () => {
  return (
    <>
    <Helmet>
        <link href="https://python.dotsquares.com/projects" rel="canonical"/>
        <title>Projects - Pythonsquares</title>
        <meta name="description" content="Our Clients are our alibi. Pythonsquares lets you have a look into some successful Python projects delivered by us. Make your’s next." />
    </Helmet>
       
    <Box sx={{ py:7 }}>
        <Container maxWidth="md">
            <Typography variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>Our <strong>Successful Projects</strong></Typography>
            <div class="bar"></div>
            <Typography variant="body1" sx={{mb:5, textAlign:'center', fontWeight:500}}>Our vetted & handpicked Python developers has successfully deployed Python projects and set an excellent example of programming.</Typography>
        </Container>    
        <Container maxWidth="xl">
            <Grid container item direction="row" spacing={3}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card variant="outlined" sx={{textAlign:'center'}}>
                        <CardContent style={{padding:'10px'}}>
                            <img width="100%" src={portfolio01} alt="portfolio01" />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card variant="outlined" sx={{textAlign:'center'}}>
                        <CardContent style={{padding:'10px'}}>
                          <img width="100%" src={portfolio03} alt="portfolio03" />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card variant="outlined" sx={{textAlign:'center'}}>
                        <CardContent style={{padding:'10px'}}>
                          <img width="100%" src={cleaner} alt="cleaner" />                          
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container> 
    </Box>

    </>
  )
}

export default Projects