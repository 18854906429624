import React from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import ResponsiveHeader from 'dna-responsive-nav'
// import 'semantic-ui-css/semantic.min.css'
import 'dna-responsive-nav/dist/dna-rn.css'


const navbar = () => {
  let activeStyle = {
    textDecoration: "",
};

let activeClassName = "active";

const navigate = useNavigate();

const handleClick = (route) => {
  navigate(route); 
  window.scrollTo({
    top: 0,
    behavior: 'instant',
  });

  document.querySelector('.cancel-icon').click();
  
  
}


const links = (
      <ul style={{p:'0px'}}>
        <li>
          <NavLink
            to="/"
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
            // onClick = {()=>document.getElementsByClassName('nav-items').remove()}
            onClick ={() => handleClick("/")}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="services"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
            // onClick = {()=>document.getElementsByClassName('nav-items').remove()}
            onClick = {() => handleClick("services")}
          >
            Services
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to="whypython"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
            // onClick = {()=>document.getElementsByClassName('nav-items').remove()}
            onClick = {() => handleClick("whypython")}
          >
            Why Python
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to="projects"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
            // onClick = {()=>document.getElementsByClassName('nav-items').remove()}
            onClick = {() => handleClick("projects")}
          >
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink
            to="casestudies"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
            // onClick = {()=>document.getElementsByClassName('nav-items').remove()}
            onClick = {() => handleClick("casestudies")}
          >
            Case Studies
          </NavLink>
        </li>
        <li>
          <NavLink
            to="certifications"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
            // onClick = {()=>document.getElementsByClassName('nav-items').remove()}
            onClick = {() => handleClick("certifications")}
          >
            Certifications
          </NavLink>
        </li>
        <li>
          <NavLink
            to="news"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
            // onClick = {()=>document.getElementsByClassName('nav-items').remove()}
            onClick = {() => handleClick("news")}
          >
            News
          </NavLink>
        </li>
      </ul>
)

return (
    <ResponsiveHeader
      links={links}
    />
  )
}

export default navbar