import React from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Ecommerce from '../../../images/Ecommerce.svg';
import Fintech from '../../../images/Fintech.svg';
import healthCare from '../../../images/healthCare.svg';
import insuranceDoc from '../../../images/insuranceDoc.svg';
import onlineEdu from '../../../images/onlineEdu.svg';
import ConsultationFormPopup from './ConsultationFormPopup';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const Industry = () => {
  return (
    <Grid className="blueColour" sx={{ py:7, textAlign:'center' }}>
        <Container maxWidth="lg">
            <Typography className="" variant="h4" component="h4" sx={{textTransform:'uppercase', mb:3, textAlign:'center'}}>We Serve <strong>Diverse industry verticals</strong></Typography>
            <div class="bar"></div>
            <Grid container item direction="row" spacing={3} sx={{justifyContent:'center',  textAlign:'center', my:4}}>
                <Grid item sm={4} lg={2} xs={6}>
                    <Card sx={{ minHeight: 200, textAlign: 'center', borderRadius:'10px', }} className="primaryColor">
                        <CardContent sx={{ pt: 6}}>
                            <img src={Ecommerce} alt="Ecommerce" height="70px" />
                            <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my:2, fontWeight: '600', textTransform:'uppercase' }} >Ecommerce</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4} lg={2} xs={6}>
                    <Card sx={{ minHeight: 200, textAlign: 'center', borderRadius:'10px' }} className="primaryColor">
                        <CardContent sx={{ pt: 6}}>
                            <img src={Fintech} alt="Fintech" height="70px" />
                            <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my:2, fontWeight: '600', textTransform:'uppercase' }} >Fintech</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4} lg={2} xs={6}>
                    <Card sx={{ minHeight: 200, textAlign: 'center', borderRadius:'10px' }} className="primaryColor">
                        <CardContent sx={{ pt: 6}}>
                            <img src={healthCare} alt="healthCare" height="70px" />
                            <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my:2, fontWeight: '600', textTransform:'uppercase' }} >HealthCare</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4} lg={2} xs={6}>
                    <Card sx={{ minHeight: 200, textAlign: 'center', borderRadius:'10px' }} className="primaryColor">
                        <CardContent sx={{ pt: 6}}>
                            <img src={insuranceDoc} alt="insuranceDoc" height="70px" />
                            <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my:2, fontWeight: '600', textTransform:'uppercase' }} >Insurance</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4} lg={2} xs={6}>
                    <Card sx={{ minHeight: 200, textAlign: 'center', borderRadius:'10px' }} className="primaryColor">
                        <CardContent sx={{ pt: 6}}>
                            <img src={onlineEdu} alt="onlineEdu" height="70px" />
                            <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my:2, fontWeight: '600', textTransform:'uppercase' }} >Education</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


            {/* <Button variant="contained" color="primary" size="large" href="/#top">Let’s Discuss About Your Industry</Button> */}

            <Popup trigger= {
                <Button variant="contained" color="primary" size="large">Let’s Discuss About Your Industry</Button>}
                modal nested>
                    {
                        close => (
                            <div className='modal'>
                                <div className='content'>
                                    <ConsultationFormPopup />
                                </div>
                                <div class="popupClose">
                                    <Button variant="contained" color="secondary" onClick=
                                        {() => close()}>
                                            X
                                    </Button>
                                </div>
                            </div>
                        )
                    }
            </Popup>


        </Container>
    </Grid>
  )
}

export default Industry