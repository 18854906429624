import { useState } from "react";
import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import iconsPython from '../../../images/iconsPython.gif';
import { Api } from "../../../Api";
import { useNavigate } from "react-router-dom";

function ConsultationFormPopup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [flash, setflash] = useState("");
  const [errors, setErrors] = useState([]);
  const [disable, setDisable] = useState(false);

  const navigate = useNavigate(); 

  let handleSubmit = async (e) => {
    setDisable(true);
    e.preventDefault();  
    try {

      const referrerURL = localStorage.getItem("referrerURL");
      const siteURL = localStorage.getItem("siteURL");
      //console.log("referrer url 2", referrerURL);

      let res = await fetch(Api.newConsultationEntry, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          email: email,
          phone: mobileNumber,
          message: message,
          referrerurl: referrerURL,
          siteurl: siteURL
        }),
      });
      let resJson = await res.json();

      //console.log(res);
      
      if (res.status === 422) {
        setErrors(resJson.errors);
      }
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMobileNumber("");
        setMessage("");
        setErrors([]);
        setflash("Your Details Submitted Successfully");
        // this.props.history.push('/thankyou');
        // navigate('/thankyou'); 
        window.location = window.location.protocol + '//' + window.location.host + '/thankyou';
      } 
      setDisable(false);
    } catch (err) {
      setDisable(false);
      console.log(err);
    }
    
  };

  return (

   
        <Card sx={{ minWidth: 275, p:2, mt:5, position:'relative', borderRadius:'10px', overflow:'inherit' }}>
            <Typography className="semiBold" variant="h5" component="h5" sx={{textAlign:'center'}}>Request a Call back</Typography>
            <Typography variant="p" component="p" sx={{textAlign:'center', my:1}}>Get a free initial consultation for Python Services</Typography>
            
            
            <form onSubmit={handleSubmit} id="gform_1">
                <CardContent sx={{p:1}}>
                    <Box sx={{display:'grid', gap:2}}>
                        <TextField fullWidth 
                          id="your-name" 
                          label="Your Name*"
                          variant="outlined" 
                          value={name} 
                          onChange={(e) => setName(e.target.value)} 
                          helperText={errors.name ? errors.name : ""} 
                          error={errors.name} 
                        />
                        <TextField fullWidth 
                          id="email-address" 
                          label="Email Address*" 
                          variant="outlined" 
                          value={email} 
                          onChange={(e) => setEmail(e.target.value)} 
                          helperText={errors.email ? errors.email : ""} 
                          error={errors.email} 
                        />
                        <TextField fullWidth 
                          id="phone-number" 
                          label="Phone Number*" 
                          minRows={1}
                          variant="outlined" 
                          value={mobileNumber}  
                          onChange={(e) => setMobileNumber(e.target.value)} 
                          helperText={errors.phone ? errors.phone : ""} 
                          error={errors.phone} 
                        />
                        <TextareaAutosize fullWidth
                            aria-label="Message"
                            minRows={3}
                            placeholder="Requirement Brief"
                            variant="outlined" 
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            helperText={errors.message ? errors.message : ""}
                            error={errors.message}
                        />
                    </Box>
                </CardContent>
                <CardActions sx={{textAlign:'center', justifyContent:'center'}}>
                    <Button type="submit" fullWidth size="large" className="blueColour white" disabled={disable}>{disable ? 'Please wait..' : 'Submit'}</Button>
                </CardActions>
                <Box sx={{color:'success.main', textAlign:'center'}}>{flash ? flash : null}</Box>
            </form>
        </Card>

  );
}

export default ConsultationFormPopup;