import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import forbes from '../../../images/forbes.png';
import ecommerceawards from '../../../images/ecommerceawards.jpg';
import asianachieversawards from '../../../images/asianachieversawards.png';

const Awards = () => {
  return (
    <Box sx={{ py:7, bgcolor:'black' }}>
        <Container maxWidth="xl">
            <Grid container items directions="row" spacing={3} sx={{alignItems:'center', justifyContent:'space-between'}}>
                <Grid item sm={4} xs={12}>
                    <Typography color="white" variant="h4" component="h4" sx={{mb:2, textAlign:'left', textTransform:'uppercase'}}>Awards & <br /><strong>Recognitions</strong></Typography>
                    <Typography color="white" variant="body1" sx={{mb:3, textAlign:'left'}}>We have always been recognized for our work and awarded with industry accolades for delivering excellent results.</Typography>
                </Grid>
                <Grid item sm={7} xs={12} >
                  <Grid container direction="row" spacing={3} sx={{alignItems:'center'}}>
                      <Grid item sm={4}><img width="100%" src={forbes} alt="forbes" /></Grid>
                      <Grid item sm={4}><img className="awardIcons" width="100%" src={ecommerceawards} alt="ecommerceawards" /></Grid>
                      <Grid item sm={4}><img className="awardIcons" width="100%" src={asianachieversawards} alt="asianachieversawards" /></Grid>
                  </Grid>
                </Grid>
            </Grid>
        </Container>
    </Box>
  )
}

export default Awards