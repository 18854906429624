import React from 'react'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Api } from '../../../Api';
import Expertise from '../../../images/Expertise.png';


class OurExpertise extends React.Component {
    render() {

        var leftColumn = [];
        var middleColumn = [];
        var rightColumn = [];
        
        for (var i = 0; i < this.props.expertiseSection.expertises.length; i++) {

            if(i<3){
                leftColumn.push(<ListItem>
                    <div className="expertiseIcon"><img src={Api.siteUrl+"images/expertise/"+this.props.expertiseSection.expertises[i].icon} alt={this.props.expertiseSection.expertises[i].icon} /></div>
                    <div className="expertiseText">
                        {
                            this.props.expertiseSection.expertises[i].slug ? (
                            <Link href={"/expertises/"+this.props.expertiseSection.expertises[i].slug}>
                            <ListItemText primary={this.props.expertiseSection.expertises[i].title} /></Link>
                            ):(
                                <ListItemText primary={this.props.expertiseSection.expertises[i].title} />
                            )
                        }
                    </div>
                </ListItem>);
            } else if (i<7){
                middleColumn.push(<ListItem>
                    <div className="expertiseIcon"><img src={Api.siteUrl+"images/expertise/"+this.props.expertiseSection.expertises[i].icon} alt={this.props.expertiseSection.expertises[i].icon} /></div>
                    <div className="expertiseText">
                        {
                            this.props.expertiseSection.expertises[i].slug ? (
                            <Link href={"/expertises/"+this.props.expertiseSection.expertises[i].slug}>
                            <ListItemText primary={this.props.expertiseSection.expertises[i].title} /></Link>
                            ):(
                                <ListItemText primary={this.props.expertiseSection.expertises[i].title} />
                            )
                        }
                    </div>
                </ListItem>);
            } else if(i<10){
                rightColumn.push(<ListItem>
                    <div className="expertiseIcon"><img src={Api.siteUrl+"images/expertise/"+this.props.expertiseSection.expertises[i].icon} alt={this.props.expertiseSection.expertises[i].icon} /></div> 
                    <div className="expertiseText">
                        {
                            this.props.expertiseSection.expertises[i].slug ? (
                            <Link href={"/expertises/"+this.props.expertiseSection.expertises[i].slug}>
                            <ListItemText primary={this.props.expertiseSection.expertises[i].title} /></Link>
                            ):(
                                <ListItemText primary={this.props.expertiseSection.expertises[i].title} />
                            )
                        }
                    </div>
                </ListItem>);
            }
        }


        return (
            <Grid className="lightGrey" sx={{ py:10 }}>
                <Container maxWidth="lg">
                    <Typography className="" variant="h4" component="h4" sx={{textTransform:'uppercase', mb:2, textAlign:'center'}}> <div dangerouslySetInnerHTML={{ __html:this.props.expertiseSection.title}}/></Typography>
                    <div class="bar"></div>
                    <Typography variant="body1" sx={{mb:6, fontWeight:'500', textAlign:'center'}}>{this.props.expertiseSection.description}</Typography>
                </Container>    
                <Container maxWidth="xl">
                    <Grid container items direction="row" spacing={3}>
                        <Grid item sm={6} lg="4" xs="12" className="expertiseBlock">
                            <List sx={{}}>
                                {leftColumn}
                            </List>
                        </Grid>
                        <Grid item sm={6} lg="4" xs="12" className="expertiseBlock">
                            <List>
                                {middleColumn}
                            </List>
                        </Grid>
                        <Grid item sm={6} lg="4" xs="12" className="expertiseBlock">
                            <List>
                                {rightColumn}
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }
}

export default OurExpertise