import React from 'react'
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import ConsultationFormPopup from '../Pages/Home/ConsultationFormPopup';
import Popup from 'reactjs-popup';
import FacebookOutlined from '@mui/icons-material/FacebookOutlined';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';
import YouTube from '@mui/icons-material/YouTube';
import 'reactjs-popup/dist/index.css';
import Link from '@mui/material/Link';


class footer extends React.Component {

 

  render() {
    const services = this.props.services;

    return (
    
      <Box component="footer">
          <Box display="block" className="primaryColor" sx={{py:5}}>
            <Container maxWidth="lg" sx={{textAlign:'center'}}>
                <Grid container items direction="row" sx={{alignItems:'center', textAlign:'center', justifyContent:'center'}}>
                  <Typography className="" variant="h5" component="h5" sx={{textAlign:'center', mb:1, fontWeight:500}}>Wondering where to start? </Typography>
                  <Typography className="" variant="h4" component="h4" sx={{textAlign:'center', mb:3}}>How about a <strong>Free Expert Consultation</strong> Or <strong>A Free Quotation?</strong></Typography>
                  {/* <Button variant="contained" color="secondary" size="large" onClick={homePageTop}>Let’s Talk</Button> */}

                  <Popup trigger= { 
                    <Button variant="contained" color="secondary" size="large">Let’s Talk</Button>}
                  modal nested>
                      {
                          close => (
                              <div className='modal'>
                                  <div className='content'>
                                      <ConsultationFormPopup />
                                  </div>
                                  <div class="popupClose">
                                      <Button variant="contained" color="secondary" onClick=
                                          {() => close()}>
                                              X
                                      </Button>
                                  </div>
                              </div>
                          )
                      }
                  </Popup>
                </Grid>
            </Container>
          </Box>
        
        <Toolbar className="footerBar" sx={{bgcolor:'black', color:'white', fontWeight:400}}>
          <Container maxWidth="xl" sx={{p:2, textAlign:'left'}}>
            <Grid container direction="row" sx={{py:4}}>
              <Grid item sm={7}>
                  <h2>Contact</h2>
                  <div class="office-address">
                    <ul>
                        <li><strong>BRIGHTON, UK </strong> Unit 2, Albourne Court, Henfield Road, Albourne, West Sussex, BN6 9FF <span class="phone-no"> Uk Tel: <a href="tel:+441273575190"> +44 1273 575190</a></span></li>
                        <li><strong>LONDON, UK </strong>Unit 2 Hobbs Court, 2 Jacob Street, London SE1 2BG <span class="phone-no"> Tel: <a href="tel:(+44)2080901819">(+44) 208 0901819</a></span></li>
                        <li><strong>DUBAI UAE </strong>10, Bayti 22, Street 35, Al Safa 2 Dubai, UAE <span class="phone-no">Tel: <a href="tel:+971504227935">+97150-4227935</a>, <a href="tel:+97143284525">+9714-3284525</a></span></li>
                        <li><strong>USA 6701</strong> Democracy Blvd.Suite 300, Bethesda,MD 20817 <span class="phone-no">Tel: <a href="tel:(+1)3015639488">(+1) 301 563 9488</a></span></li>
                        {/* <li><strong>CANADA </strong> 300 Market Drive, Milton, Ontario, Canada, L9T 5A4 <span class="phone-no"> Tel: <a href="tel: +1(289)9371775"> +1 (289) 937-1775</a></span>
                        </li> */}
                        <li><strong>CANADA </strong> 20 Bay Street 11th Floor #604, Toronto, Canada, M6J 2N8 <span class="phone-no"> Tel: <a href="tel: +1(289)9371775"> +1 (289) 937-1775</a></span>
                        </li>
                        <li><strong>AUSTRALIA </strong>11/475 Blackburn Road, Mount Waverley, Vic 3149 <span class="phone-no"> Tel: <a href="tel:(+61)0395115598">(+61) 03 9511 5598</a></span></li>
                        <li><strong>FRANCE </strong>506 Chemin des Nugues, 38430 St Jean de Moirans <span class="phone-no"> Tel: <a href="tel:+02080901819">+0208-090-1819 </a></span></li>

                        <li><strong>JAIPUR, INDIA </strong> 6-Kha-9, Jawahar Nagar,  Jaipur, Rajasthan - 302004, India <span class="phone-no"> Tel: <a href="tel:+919829029555">+91 98290 29555</a></span></li>
                        <li><strong>GURUGRAM, INDIA</strong> Emaar Digital Greens Tower A, 7/8 4th Floor, Sec 61, Golf Course Ext Road Gurugram, Haryana 122102                        <br /><span class="phone-no"> Tel: <a href="tel:+919799932555">+91 9799932555</a></span>
                        </li>
                    </ul>
                  </div>
              </Grid>
              <Grid item sm={3} sx={{px:2}}>
                <h2>Quick Links</h2>
                <Box className="quick-link" sx={{color:'white', fontSize:13}}>
                  {
                    services.map((service, index) => (
                      <Link sx={{color:'white', display:'block', pb:1,}} href={"/services/"+service.slug}>• {service.title}</Link>
                    ))
                  }
                  <Link sx={{color:'white', display:'block', pb:1,}} href={"/whypython/"}>• Why Python</Link>
                </Box>
              </Grid>
              <Grid item sm={2}>
                <h2>Follows Us</h2>
                <ul class="follow-us-icon">
                    <li><a href="https://www.instagram.com/dotsquaresltd/" target="_blank" rel="nofollow noreferrer noopener"><Instagram /></a></li>
                    <li><a href="https://www.facebook.com/dotsquaresuk/" target="_blank" rel="nofollow noreferrer noopener"><FacebookOutlined /></a></li>
                    <li><a href="https://www.linkedin.com/showcase/python-development-company" target="_blank" rel="nofollow noreferrer noopener"><LinkedIn /></a></li>
                    <li><a href="https://twitter.com/dotsquares" target="_blank" rel="nofollow noreferrer noopener"><Twitter /></a></li>
                    <li><a href="https://www.youtube.com/user/dotsquaresltd" target="_blank" rel="nofollow noreferrer noopener"><YouTube /></a></li>
                </ul>
              </Grid>
            </Grid>
            <Grid className="copyright" container sx={{textAlign:'center', fontSize:13, justifyContent:'center', py:1, color:'gray'}}>
                <Box sx={{mb:0.5}}>© 2002 – {(new Date().getFullYear())} Dotsquares Ltd. All rights reserved.
                Registered in England & Wales No.: 4381390 | VAT Reg.: 868 8581 51</Box>
                <Box sx={{m:0}}>Dotsquares and the Dotsquares logo are trademarks of <a href="https://www.dotsquares.com" target="_blank">Dotsquares Ltd.</a>, with the UK Trademark number UK00003160651</Box>
            </Grid>
          </Container>
        </Toolbar>
      </Box> 
    )
  
  } 

}

export default footer