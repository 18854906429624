import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import webDev from '../../../images/webDev.svg';
import mobileApp from '../../../images/mobileApp.svg';
import migration from '../../../images/migration.svg';
import API from '../../../images/API.svg';
import pythonGame from '../../../images/pythonGame.svg';
import Machine from '../../../images/Machine.svg';
import { Api } from '../../../Api';
import ReactCardFlip from 'react-card-flip';


class ourservice extends React.Component {
    constructor() {
        super();
        this.state = {
            isFlipped: true,
        };
        
    }

    handleHoverOpen = (index) => {
        console.log("entered");
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped[index]}));
    };


    handleHoverClose = (index) => {
        console.log("leave");
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped[index]}));
    };

    handleFlipOpen = (event, flip) => {
        event.preventDefault();

        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));

        console.log(flip);
    }
    handleFlipClose = (event, flip) => {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
        console.log(flip);
    }

    Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    render() {
        const services = this.props.services;
        let x = 0;
        return (
            <>
                <Grid className="lightColour">
                    <Container maxWidth="lg">
                        <Card sx={{ p: 3, position: 'relative', borderRadius: '10px', top: -50 }}>
                            <Grid container item direction="row" spacing={3}>
                                <Grid item sm={4} lg={4} xs={12}>
                                    <Box class="counter-box">
                                        <span class="counter blueColorText">50 +</span>
                                        <Box sx={{ textTransform: 'uppercase', mt: 1 }}>Python Developers</Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} lg={4} xs={12}>
                                    <Box class="counter-box">
                                        <span class="counter blueColorText">200 +</span>
                                        <Box sx={{ textTransform: 'uppercase', mt: 1 }}>Delivered Projects</Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={4} lg={4} xs={12}>
                                    <Box class="counter-box">
                                        <span class="counter blueColorText">500 +</span>
                                        <Box sx={{ textTransform: 'uppercase', mt: 1 }}>Satisfied Customers</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Container>

                    <Container maxWidth="lg" sx={{ py: 5 }}>
                        <Typography className="" variant="h4" component="h4" sx={{ textTransform: 'uppercase', textAlign: 'center' }}>Our <strong>Services</strong></Typography>
                        <div class="bar"></div>


                        <Grid container item direction="row" spacing={3} className="serviceBlock" sx={{ py: 5 }}>
                            {

                                services.map((service, index) => (

                                    <Grid item sm={6} lg={4} xs={12}>
                                        <ReactCardFlip key={index} isFlipped={this.state.isFlipped[index]} flipDirection="horizontal" sx={{cursor: 'pointer'}}>
                                            <div onClick = {()=> window.location = "/services/"+service.slug}>
                                                <Card sx={{ minHeight: 255, textAlign: 'center', borderRadius: '10px' }}>
                                                    <Box className="serviceIcon" sx={{ textAlign: 'center', p: 1, mt: 5, minHeight: 90 }}><img src={Api.siteUrl + "images/services/" + service.icon} alt="Compatible" height="60px" /></Box>
                                                    <Typography className="NavyColourText" variant="h6" component="h6" sx={{ my: 2, p:2, textTransform: 'uppercase', fontWeight: '600' }} >{service.title}</Typography>
                                                </Card>
                                            </div>
                                            <div onMouseLeave={() => this.handleHoverClose(index)}>
                                                <Link href={"/services/"+service.slug}>
                                                    <Card sx={{ textAlign: 'center', minHeight: 255, borderRadius: '10px', color: 'white', backgroundColor: 'primary.main' }}>
                                                        <Typography variant="body1" component="div" sx={{ fontWeight: '500', fontSize: '0.9rem', display: 'flex', alignItems: 'center', boxSizing: 'border-box', p: 3 }}>
                                                            {service.description}
                                                        </Typography>
                                                        <Box><Button variant="contained" color="primary" size="medium" href={"/services/"+service.slug}>Read More</Button></Box>
                                                    </Card>
                                                </Link>
                                            </div>
                                        </ReactCardFlip>

                                    </Grid>
                                ))
                            }


                        </Grid>
                    </Container>
                </Grid>
            </>
        )

    }
}

export default ourservice