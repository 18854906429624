import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import ConsultationForm from './ConsultationForm'
import banner from '../../../images/banner.jpg'



class Topbanner extends React.Component {

    Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    render() {
        return (
            <>
                <Box id="top" className="topBanner" sx={{ pt:{xs:5, md:10}, pb:15, backgroundImage: `url(${banner})`, backgroundSize: "cover", backgroundPosition:"center center" }} >
                    <Container maxWidth="xl">
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item md={7} xs={12}>
                                <Typography variant="h1" component="h1" sx={{mb:2, textTransform:'uppercase'}}>{this.props.banner.title}</Typography>
                                <div className="xlarge-copy-size " dangerouslySetInnerHTML={{
                                    __html: this.props.banner.description
                                }} />
                            </Grid>
                        <ConsultationForm />
                        </Grid>
                    </Container>
                </Box>
            </>
        )
    }
}

export default Topbanner