import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Api } from '../../../Api';
import whyChoose from '../../../images/whyChoose.jpg';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ConsultationFormPopup from './ConsultationFormPopup';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class WhyChoose extends React.Component{

    render(){
        return (
            <Grid className="NavyColour whyChoose" sx={{ py:10 }}>
                <Container maxWidth="xl">
                    <Grid container item direction="row" spacing={3} sx={{alignItems:'center'}}>
                        <Grid item sm={6} lg={6} xs={12}>
                            <Typography className="white" variant="h4" component="h4" sx={{textTransform:'uppercase', mb:1, textAlign:'left'}}><div dangerouslySetInnerHTML={{ __html:this.props.whyChooseSection.title}}/></Typography>
                            <Typography className="white" variant="body1" sx={{mb:4, textAlign:'left'}}>
                                <div dangerouslySetInnerHTML={{ __html:this.props.whyChooseSection.description}}/>
                            </Typography>
                            <img src={Api.siteUrl + "images/sections/" + this.props.whyChooseSection.image} alt="Why Choose" width="100%" />
                        </Grid>
                        <Grid item sm={6} lg={6} xs={12}>
                            <Card sx={{p:6, borderRadius: '10px'}}>
                                <Grid container item direction="row" alignItems="center">
                                    {
                                        this.props.whyChooseSection.types.map((wcType) => (
                                            <Grid item sm={6} lg={6} xs={12} sx={{textAlign:'center', py:5}}>
                                                <img src={Api.siteUrl+"images/why_choose/"+wcType.icon} alt={wcType.icon} height="55px" />
                                                <div class="counter-box">
                                                    <Typography variant="p" component="p" sx={{my:5, textTransform:'uppercase', lineHeight:1.2}} dangerouslySetInnerHTML={{ __html: wcType.title }}></Typography>
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                {/* <Box sx={{textAlign:'center', mt:6}}><Button variant="contained" color="secondary" size="large" href="/#top">Let’s Brainstorm</Button></Box> */}

                                <Box sx={{textAlign:'center', mt:6}}>
                                    <Popup trigger= { 
                                        <Button variant="contained" color="secondary" size="large">Let’s Brainstorm</Button>}
                                        modal nested>
                                            {
                                                close => (
                                                    <div className='modal'>
                                                        <div className='content'>
                                                            <ConsultationFormPopup />
                                                        </div>
                                                        <div class="popupClose">
                                                            <Button variant="contained" color="secondary" onClick=
                                                                {() => close()}>
                                                                    X
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                    </Popup>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }

}
export default WhyChoose