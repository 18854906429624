import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import cloudModern from '../../../images/cloudModern.jpg';
import { Helmet } from 'react-helmet';
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { Api } from '../../../Api';
import Loader from "react-js-loader";

class NewsDetails extends React.Component {

    // Constructor 
    constructor(props) {
        super(props);

        let path =  window.location.pathname;
        let pathArr = path.split("/");

        this.blogID = pathArr;

        this.state = {
            blog: {},
            DataisLoaded: false,
        };

        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });
    }
    
    
    componentDidMount = () => {
        fetch(Api.blogDetails+'/'+this.blogID[2])
            .then((res) => res.json())
            .then((json) => {

                // console.log(json.blog_details);
                this.setState({
                    blog: json.blog_details,
                    DataisLoaded: true
                });
 
            })          
    }

    render() {
        const { DataisLoaded, blog } = this.state;


        const list = []

        if (!DataisLoaded) {return <div className={"item"}>
        <Loader type="spinner-circle" bgColor={"rgb(30, 62, 91)"} title={"Loading..."} color={'rgb(30, 62, 91)'} size={100} />
    </div>;}
     else{
            return (
                <>
                    <Helmet>
                        <link href={window.location.href} rel="canonical"/>
                        <title>{blog.meta_title}</title>
                        <meta name="description" content={blog.meta_description} />

                        <meta property='og:url' content={window.location.href} />
                        <meta property='og:title' content={blog.meta_title} />
                        <meta property='og:description' content={blog.meta_description} />
                        <meta property='og:image' content={Api.siteUrl + "posts_images/banner/" + blog.banner_image} />

                        <meta name='twitter:card' content='summary_large_image' />
                        <meta property='twitter:url' content={window.location.href} />
                        <meta name='twitter:title' content={blog.meta_title} />
                        <meta name='twitter:description' content={blog.meta_description} />
                        <meta name='twitter:image' content={Api.siteUrl + "posts_images/banner/" + blog.banner_image} />
                    </Helmet>

                    <Box className="" sx={{ py:6 }}>
                        <Container maxWidth="xl" sx={{my:4}}>
                            <img src={Api.siteUrl + "posts_images/banner/" + blog.banner_image} alt={blog.img_alt_name} width="100%" />
                            <h1>{blog.title}</h1>    
                            <div dangerouslySetInnerHTML={{ __html:blog.content}} />
                        </Container>
                    </Box>
                </>
            )
        }
    }
}


export default NewsDetails;