import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider  } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'

const theme = createTheme ({
  typography: {
    fontFamily: [
      '"Montserrat"', 'Open Sans',
      'sans-serif'
    ].join(','),
    htmlFontSize: 16,
    h1:{
      fontSize:'3rem',
      fontWeight:700,
    },
    h2:{
      fontSize:'2.3rem',
      fontWeight:600,
    },
    h3:{
      fontSize:'2rem',
      fontWeight:500,
    },
    
  },


  palette: {
    primary: {
      main: '#2B5A83',
    },
    secondary: {
      main: '#2B5A83',
    },
  },
 
});






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App /> 
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
