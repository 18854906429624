import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import megasearch from '../../../images/megasearch.png';
import django from '../../../images/django.webp';
import AWS from '../../../images/AWS.webp';
import python from '../../../images/python.webp';
import megasearchscreen from '../../../images/megasearchscreen.png';
import megasearchlist from '../../../images/megasearchlist.png';
import macscreen from '../../../images/macscreen.png';
import javascript from '../../../images/javascript.png';
import livestreaming from '../../../images/livestreaming.png';
import stripe from '../../../images/stripe.png';



const CaseStudyDetail = () => {
  return (
    <>
    <Box className="lightGrey" sx={{ py:7 }}>
        <Container maxWidth="xl">
            <Grid item xs={12}>
              <Typography variant="h6" sx={{mb:0, textAlign:'center', color:'primary.main', textTransform:'uppercase'}}>Case Study</Typography>
              <Box sx={{textAlign:'center', mb:4}}><img src={megasearch} alt="megasearch" width="280" /></Box>
            </Grid>
            <Grid container items display="row" spacing={3} sx={{alignItems:'center', flexDirection:'row-reverse'}}>
                <Grid item md={6} xs={12}><img src={macscreen} alt="macscreen" width="100%" /></Grid>
                <Grid item md={6} xs={12}>
                     <h5>About The Company</h5>
                      <p>Mega Search was founded by Zed Phoenix and Julia Sieckmann, after successfully raising money on a popular crowdfunding site. This was in response to Zed writing an article that went viral when he discovered that Google was allegedly attempting to take control of the internet by limiting the size of the web. Google allegedly searches less than 0.0024% of what’s available online but claimed to have billions of search results, which simply wasn’t true. Google also censors information whilst conducting intrusive surveillance to make billions of dollars, from the data provided for free by an unsuspecting civilisation. </p>
                      <p >Mega Search strives to create a more transparent platform for viewers to search for any type of information or lead to genuine business listings. They simultaneously wanted to create a platform for businesses to list themselves for more exposure.</p>
                      <p >The platform prompts the right to true information, knowledge and choice which presumably other search engines lack. With a unique approach, the UK company knocked the doors of Dotsquares.</p>
                      <p >Location: UK </p>
                      <p >Industry: Utilities </p>
                      <p >Partnership Since: Nov 2021</p>
                      <p >Team Size: 4-6 members</p>
                      <p >Business Link: <a target="_blank" rel="noopener noreferrer" href="https://mega-search.org">https://mega-search.org</a></p>

                      <h5>Objectives </h5>
                      <p >The objective is to develop a search engine that will run privately. The visitor will be able to view the search result of registered users on the system. The list also contains the search result of third-party provider gigablast.</p>
                      <p >There are two types of frontend users the system is catering to:</p>
                      <p >Visitors and Business Owners.</p>

                      <p ><strong>Visitors</strong> will be able to search for the business by entering the keyword in the search box. According to the keyword, the business listing will be displayed. By selecting a particular business, the visitor will land on the detail page. </p>
                      <p >Moreover, the customer/viewer will be able to report the business list if they find abusive content uploaded on-site.</p>
                      <p ><a target="_blank" rel="noopener noreferrer" href="https://mega-search.org">https://mega-search.org</a></p>

                      <p ><strong>Business Owners</strong> will be able to select/purchase the plan to add their business to the listing by registering/logging into the website. The owners will also be able to promote their business by upgrading the existing plan.</p>

                      <h5>Challenges</h5>
                      <p >Despite a clear vision in mind, they lack the tools and expertise to bring their product to life. A secure and scalable platform which will increase in size (probably functionalities) with more data. They were adamant about not losing their clientele due to uncompromised services. We suggested them a Python-based MVP. </p>
                </Grid>
              </Grid>
        </Container>
    </Box>
    <Box className="NavyColour" sx={{ py:3 }}>
      <Container maxWidth="xl">
          <Grid directions="row" spacing={3} container items sx={{textAlign:'center', alignItems:'center', justifyContent:'space-between'}}>
              <Grid item sm={4} md={2} xs={12}><img src={django} alt="django" width="130" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={javascript} alt="javascript" width="60" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={AWS} alt="AWS" width="140" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={livestreaming} alt="livestreaming" width="65" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={python} width="170" alt="python" /></Grid>
              <Grid item sm={4} md={2} xs={12}><img src={stripe} width="90" alt="stripe" /></Grid>
          </Grid>
      </Container>
    </Box>
    <Box className="lightGrey" sx={{ py:7 }}>
        <Container maxWidth="xl">
            <Grid container items display="row" sx={{alignItems:'center'}} spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box sx={{textAlign:'center'}}><img src={megasearchscreen} alt="megasearchscreen" width="80%" /></Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <h5>Features</h5>
                  <p >
                  <strong>1. Create Business Listing</strong>
                  <p>The users (business owners) will be able to create the business listing by providing mandatory business details. They will also be able to view the listing which is created by them under the “My listing section”. Last but not the least, they will be able to edit the listing details.</p>

                  <strong>2. Promote Business</strong>
                  <p>The business owners will be able to promote their brand on the homepage by purchasing the “Vision Plan”. The user will be able to select the date and position of the screen where they want the promotional video to be played.</p>

                  <strong>3. Report on the List </strong>
                  <p>The user will be able to report any business, in case they find any abusive content on the listing.</p>

                  <strong>4. Newsletter</strong>
                  <p>The user will be able to subscribe to the Newsletter just by checking the box which is there on the registration page.</p>
                  <p>Python supports some of the robust websites/software of the current time. As Mega Search, several unique businesses have relied on Python to solve a problem or highlight their USP to the world. </p>
                  </p>
                </Grid>
            </Grid>
            <Grid container items display="row" sx={{alignItems:'center'}} spacing={3}>
                <Grid item md={6} xs={12}>
                  <h5>Delivered Solution</h5>
                  <p >
                      <ul>
                        <li>Expert consultations tailored specifically to their requirements.</li>
                        <li>The business owner will be able to register/login on to the website to add their business to the platform.</li>
                        <li>The business owner will be able to add the post of their business based on the purchased plan.</li>
                        <li>The visitors will be able to view the business based on their search keywords.</li>
                        <li>The business owners will be able to promote their business.</li>
                        <li>The visitors will be able to report the list if they find the content inappropriate to any individual or community.</li>
                        <li>The business owners will be able to subscribe to the Newsletters.</li>
                      </ul>
                  </p>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box sx={{textAlign:'center'}}><img src={megasearchlist} alt="megasearchlist" width="80%" /></Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
   
    
  </>
  )
}

export default CaseStudyDetail