//Live API
const apiUrl = 'https://python.dotsquares.com/backend/public/api/';
const siteUrl = 'https://python.dotsquares.com/backend/public/';

// // //Demo API
// const apiUrl = 'https://subsitepython.24livehost.com/backend/public/api/';
// const siteUrl = 'https://subsitepython.24livehost.com/backend/public/';

// local API
// const apiUrl = 'https://subsitepython.local:8447/api/';
// const siteUrl = 'https://subsitepython.local:8447/';

export const Api = {
    apiUrl: apiUrl,
    siteUrl: siteUrl,
    homePage: apiUrl+'home-page-data',
    newConsultationEntry: apiUrl+'new-consultation-entry',
    blogCategoriesWithPostsList: apiUrl+'blog-categories-with-posts-list',
    blogsList: apiUrl+'blogs-list',
    blogDetails: apiUrl+'blog-details',
    servicesList: apiUrl+'services-list',
    servicesDetails: apiUrl+'service-details',
    expertiseList: apiUrl+'expertise-list',
    expertiseDetails: apiUrl+'expertise-details',
    CaseStudyDetail: apiUrl+'case-study-details',
    caseStudiesList: apiUrl+'case-studies-list',
    servicesList: apiUrl+'services-list',
}    


