import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import aws1 from '../../../images/aws1.png';
import aws2 from '../../../images/aws2.png';
import aws3 from '../../../images/aws3.png';
import { Helmet } from 'react-helmet';

const Certifications = () => {
  return (
    <>
    <Helmet>
        <link href="https://python.dotsquares.com/certifications" rel="canonical"/>
        <title>Certifications - Pythonsquares</title>
        <meta name="description" content="If we say certified Python Experts We mean it. Pythonsquares holds an extensive team of AWS-certified Python experts on your project. You deserve the best." />
    </Helmet>
    
    <Box className="whiteColour" sx={{ py:7 }}>
        <Container maxWidth="lg">
            <Typography variant="h4" component="h4" sx={{mb:5, textAlign:'center'}}>Our <strong>Certified Developers</strong></Typography>
            <div class="bar"></div>
            <Grid container item direction="row" spacing={3} sx={{justifyContent:'center', py:4}}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{ minWidth: 275, minHeight:300, textAlign:'center'}}>
                        <CardContent sx={{py:4}}>
                            <Box sx={{mb:3}}><img src={aws1} alt="aws1" width="180" /></Box>
                            <Typography variant="h5" component="h5" sx={{mb:0, fontWeight:600}}>Suresh Suthar</Typography>
                            <Typography variant="h6" component="h6" sx={{mb:2,}}>AWS Well-Architected</Typography>
                            <Typography variant="body1" component="div" sx={{fontWeight:500}}>
                                Certificate ID: E-03M461
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{ minWidth: 275, minHeight:300, textAlign:'center'}}>
                        <CardContent sx={{py:4}}>
                            <Box sx={{mb:3}}><img src={aws2} alt="aws2" width="180" /></Box>
                            <Typography variant="h5" component="h5" sx={{mb:0, fontWeight:600}}>Mahesh Yadav</Typography>
                            <Typography variant="h6" component="h6" sx={{mb:2,}}>AWS Certified Cloud Practitioner</Typography>
                            <Typography variant="body1" component="div" sx={{fontWeight:500}}>
                                Certificate ID: AWS02487797
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{ minWidth: 275, minHeight:300, textAlign:'center'}}>
                        <CardContent sx={{py:4}}>
                            <Box sx={{mb:3}}><img src={aws3} alt="aws3" width="180" /></Box>
                            <Typography variant="h5" component="h5" sx={{mb:0, fontWeight:600}}>Gopal Jha</Typography>
                            <Typography variant="h6" component="h6" sx={{mb:2,}}>AWS Certified Developer - Associate</Typography>
                            <Typography variant="body1" component="div" sx={{fontWeight:500}}>
                                Certificate ID: X73NXVGJFBQ41B39
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </Box>
    </>
  )
}

export default Certifications