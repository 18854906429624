import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import CardContent from '@mui/material/CardContent';
import portfolio01 from '../../../images/portfolio01.jpg';
import portfolio03 from '../../../images/portfolio03.jpg';
import projectTwo from '../../../images/projectTwo.jpg';
import { Api } from '../../../Api';
import Loader from "react-js-loader";

class CaseStudy extends React.Component {

    handleClick = (url) => {
        window.location.href = url; // Redirect to the specified URL
      };

    render() {
        return (
            <Box sx={{ py:7, textAlign:'center' }}>
                <Container maxWidth="md">
                    <Typography className="" variant="h4" component="h4" sx={{textTransform:'uppercase', mb:1, textAlign:'center'}}><div dangerouslySetInnerHTML={{ __html:this.props.caseStudySection.title}} /></Typography>
                    <div class="bar"></div>
                    <Typography variant="body1" sx={{fontWeight:'500', textAlign:'center'}}>{this.props.caseStudySection.description}</Typography>
                </Container>    
                <Container maxWidth="xl">
                    <Grid container direction="row" spacing={3} sx={{justifyContent:'center', textAlign:'center', my:5}}>
                    {

                    this.props.caseStudies.map((caseStudy, index) => (
                        <Grid item sm={3}>
                            <Card variant="outlined" sx={{textAlign:'center'}}>
                                <CardContent style={{padding:'10px'}}>
                                    <Link href={`/case-studies/${caseStudy.slug}`}><img width="100%" src={Api.siteUrl + "images/case-studies/" + caseStudy.portfolio_image} alt={caseStudy.title} /></Link>
                                </CardContent>
                            </Card>
                        </Grid>
                        ))
                    }
            
                    </Grid>
                    <Button onClick={() => this.handleClick("/casestudies")} variant="contained" color="secondary" size="large">View More</Button>
                </Container>
            </Box>
        )
    }
}

export default CaseStudy