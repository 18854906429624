import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import logo01 from '../../../images/logo01.jpg';
import logo02 from '../../../images/logo02.jpg';
import logo03 from '../../../images/logo03.jpg';
import logo04 from '../../../images/logo04.jpg';
import logo05 from '../../../images/logo05.jpg';
import logo06 from '../../../images/logo06.jpg';
import logo07 from '../../../images/logo07.jpg';
import logo08 from '../../../images/logo08.jpg';


const responsive = {
    0: { items: 2 },
    568: { items: 4 },
    1024: { items: 5 },
    1366: { items: 7 },
};

const items = [
    
        <Box className="item" data-value="1" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo01} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
        <Box className="item" data-value="2" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo02} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
        <Box className="item" data-value="3" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo03} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
        <Box className="item" data-value="4" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo04} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
        <Box className="item" data-value="5" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo05} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
        <Box className="item" data-value="6" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo06} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
        <Box className="item" data-value="7" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo07} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
        <Box className="item" data-value="8" sx={{p:1}}>
            <Card variant="outlined" sx={{textAlign:'center'}}>
                <CardContent style={{padding:'15px'}}>
                    <img className="objectContent" width="100%" height="50px" src={logo08} alt="logo" />
                </CardContent>
            </Card>
        </Box>,
  
];

const Clients = () => {
  return (
     
        <Grid className="NavyColour clientSection" sx={{ py:5, justifyContent:'center'}}>
            <Container maxWidth="xl">
                <Typography variant="h4" component="h4" sx={{color: 'white', textTransform:'uppercase', mb:1, textAlign:'center'}}>Our <strong>Clients</strong></Typography>
                <div class="bar"></div>
                <Typography variant="body1" sx={{textAlign:'center', color: 'white'}}>
                    We have always been recognized for our work and awarded with industry accolades for delivering excellent results.
                </Typography>
                <Grid container sx={{my:4}}>
                    <AliceCarousel
                        mouseTracking
                        items={items}
                        responsive={responsive}
                        controlsStrategy="alternate"
                        autoPlay="true"
                        infinite="true"
                        animationDuration="4000"
                    /> 
                </Grid>
                <Typography variant="h6" sx={{color: 'white', textAlign:'center'}}>Join over 12,000+ happy clients!</Typography>
            </Container> 
        </Grid>
   
  )
}

export default Clients