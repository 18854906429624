import React from 'react'
import logo from '../../images/logo.svg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Link, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Navbar from "./Navbar";
import ConsultationFormPopup from '../Pages/Home/ConsultationFormPopup';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const header = () => {

  const navigate = useNavigate();
  const homePageTop = () => {

    navigate("/"); //navigate to home page

    // scroll to form
    if(window.innerWidth <= 500){ // mobile
      window.scrollTo({
        top: 650,
        behavior: 'smooth',
      });
    } else if(window.innerWidth < 900){ // tablet  
      window.scrollTo({
        top: 450,
        behavior: 'smooth',
      });
    } else{ // desktop/laptop 
      window.scrollTo({
        top: 70,
        behavior: 'smooth',
      });
    }
    
  }
  
  return (
   
    <AppBar className="whiteColour" sx={{backgroundColor:'none', position:{xs:'sticky', md:'sticky'}}}>
      <Box className="topBar">
        <Container maxWidth="xl">
            <Box className="phoneno" sx={{textAlign:{xs:'center', md:'right'}}}>
              <ul>
                <li><span>UK</span> <a href="tel:+442080901819">+44 208 0901819</a> </li>
                <li><span>USA</span> <a href="tel:+13015639488">+1 301 563 9488</a> </li>
                <li><span>AUS</span> <a href="tel:+61386888288 ">(+61) 3 8688 8288 </a></li>
               </ul>
          </Box>
       </Container>
      </Box>
      <Box className="headMid" sx={{py:1}}>
        <Container maxWidth="xl">
          <Toolbar className="headerBar" sx={{flexWrap:'wrap', flexDirection:{xs:'column', md:'row'}, justifyContent:{sm:'center', lg:'space-between'}, my:{xs:0, md:0}}}>
              <Box className="logo" sx={{py:1}}>
                <Link to=""><img src={logo} alt="Dotsquares" width="280px" /></Link>
              </Box>
              <Navbar />
              <Box className="headRight" sx={{display:'flex', alignItems:'center', flexDirection:{xs:'column', md:'row'}}}>
                  {/* <Button variant="contained" color="secondary" href="/#top">Request a Call</Button> */}
                  {/* <Button variant="contained" color="secondary" onClick={homePageTop}>Request a Call</Button> */}

              <Popup trigger= {
                <Button variant="contained" color="secondary">Request a Call</Button>}
                modal nested>
                    {
                        close => (
                            <div className='modal'>
                                <div className='content'>
                                    <ConsultationFormPopup />
                                </div>
                                <div class="popupClose">
                                    <Button variant="contained" color="secondary" onClick=
                                        {() => close()}>
                                            X
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                </Popup>

              </Box>
          </Toolbar>
        </Container>
      </Box>
    </AppBar>
  )
}

export default header
