import React from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Typography } from '@mui/material';

const ThankYou = () => {
  return (
    <Box sx={{py:7, display:'flex', textAlign:'center', justifyContent:'center'}}>
        <Container maxWidth="md">
            <Card variant="outlined">
                <CardContent>
                    <CheckCircleOutlineIcon color="success" sx={{fontSize:40}} />
                    <Typography variant="h4" sx={{fontWeight:600, my:3}}>Thank You!</Typography>
                    <p>Thanks for submitting the information, we will contact you shortly.</p>
                    <Button sx={{mt:3}} variant="contained" size="large" color="secondary" href="/"><ArrowBackIosNewIcon sx={{fontSize:18, mr:1}} />Back to Home</Button>
                </CardContent>
            </Card>
        </Container>
    </Box>
  )
}

export default ThankYou