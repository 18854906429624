import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import whyPython from '../../../images/whyPython.jpg';
import commitment from '../../../images/commitment.svg';
import assistance from '../../../images/assistance.svg';
import growth from '../../../images/growth.svg';
import transparancy from '../../../images/transparancy.svg';
import CostSaving from '../../../images/CostSaving.svg';
import Compatible from '../../../images/Compatible.svg';
import TimeSaving from '../../../images/TimeSaving.svg';
import Technology from '../../../images/Technology.svg';
import HighlyEfficient from '../../../images/HighlyEfficient.svg';
import { Helmet } from 'react-helmet';


const WhyPython = () => {
  return (
    <>
     <Helmet>
        <link href="https://python.dotsquares.com/whypython" rel="canonical"/>
        <title>Unlock the Benefits of Python Development with Pythonsquares</title>
        <meta name="description" content="Python development offers numerous benefits, including its simplicity and versatility. Learn why it's the go-to language and how it can benefit your business." />
      </Helmet>
    <Box className="pythonDevelopment" sx={{ py:7, pb:11}}>
      <Container maxWidth="md">
          <Typography variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>Why is <strong>Python Development Better?</strong></Typography>
          <div class="bar"></div>
          <Typography variant="body1" sx={{mb:5, textAlign:'center', fontWeight:500}}>There are a lot of reasons to prefer Python over other programming languages, but Dotsquares personally likes its three major qualities - agility, scalability and versatility.</Typography>
      </Container>
      <Container maxWidth="xl">
          <Grid container display="row" items spacing={3} sx={{alignItems:'center'}}>
            <Grid item sm={12} md={6}>
                <img src={whyPython} alt="Why Python" width="100%"/>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography variant="body1" sx={{my:5, textAlign:'justify', fontWeight:500}}>Python development creates tailored scope to your visions of your new-age business <strong>(agility)</strong>. The programming language is singlehandedly capable of building solutions for multiple industries <strong>(versatility)</strong>. It is the perfect choice for you, even if you are going from home-based to overseas business <strong>(scalability)</strong>.</Typography>
              <Typography variant="body1" sx={{my:5, textAlign:'justify', fontWeight:500}}>For data-centric businesses of today, Python development enables Artificial Intelligence, Machine Learning and business analytical web apps with the potential to lead your business to infinite heights. Python development paves the way for success through feature-rich and dynamic solutions, whether it's web development, app development, website migration, external API integration, blockchain or data sciences.</Typography>
              <Typography variant="body1" sx={{my:5, textAlign:'justify', fontWeight:500}}>The scope of Python and services by Dotsquares is massive. Hence we have an entirely new elaborated section for “Services”.</Typography>
            </Grid>
          </Grid>
      </Container>   
    </Box>

    <Box className="NavyColour skewShape white" sx={{ py:20, mt:-13 }}>
      <Container maxWidth="lg">
          <Typography variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>How Can <strong>Dotsquares Expertise Help?</strong></Typography>
          <div class="bar"></div>
          <Typography variant="body1" sx={{mb:5, textAlign:'center'}}>Skies are the limit when it comes to development with Python. Dotsquares is on its quest to help businesses thrive with Python development, and it all happens with a team of over 100+ certified and dedicated Python developers. The most seasoned resource, having years of expertise, is associated with us.</Typography>
          <Typography variant="body1" sx={{mb:5, textAlign:'center'}}>With Dostquares, it is all an easy breeze to get work delivered by the finest talent in the dedicated time frame. The reason why Dotsquares has a 90% retention rate is that the talent here offers solutions just like a perfect dream company would. Want a top-notch Python expert on your team? Our Python Developers are now here to help you on a daily/monthly/weekly/yearly hiring model as well.</Typography>
      </Container>
       <Container maxWidth="md">
          <Typography variant="h5" component="h5" sx={{mb:2, textAlign:'center'}}>Why Dotsquares?</Typography>
          <Typography variant="body1" sx={{mb:3, textAlign:'center'}}>Our CTAG is our core value and is visible via our Code Of Conduct and project delivery.</Typography>
      </Container>
      <Container maxWidth="xl">
      <Grid container display="row" items spacing={3}>
            <Grid item sm={12} md={3}>
              <Card sx={{p:2, minHeight: 330}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={commitment} alt="commitment" width="80px"/></Box>
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Commitment</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Committed to the project requirements. With pre-planning and organized work culture, committed to delivering the project on a pre-set timeframe.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={3}>
              <Card sx={{p:2, minHeight: 330}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={transparancy} alt="transparancy" width="80px"/></Box>
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Transparency</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>We keep our valuable clients in the loop to share with them all the milestones achieved, challenges faced, and requirements regarding their project.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={3}>
              <Card sx={{p:2, minHeight: 330}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={assistance} alt="assistance" width="80px"/></Box>
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Assistance</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Dotsquares, with its team of expert Python Developers, Project managers and executives, is here to solve problems - whether it's via robust Python development or assistance with the technology.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={3}>
              <Card sx={{p:2, minHeight: 330}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={growth} alt="growth" width="80px"/></Box>  
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Growth</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Elevated graphs are the ultimate goal of the business and the Dotsquares company. Using the latest technologies alongwith expertise, we provide “the solutions of the decade” with long-term ROI.</Typography>
              </Card>
            </Grid>
          </Grid>
          <Typography variant="body1" sx={{mt:4, textAlign:'center'}}>The IT segment is dynamic and restless, and so our team of enthusiastic Python Developers.</Typography>
      </Container>   
    </Box>

    <Box className="" sx={{ py:8 }}>
      <Container maxWidth="lg">
          <Typography variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>Quick Benefits Of <strong>Python Development</strong></Typography>
          <div class="bar"></div>
      </Container>
      <Container maxWidth="xl" sx={{my:4}}>
          <Grid container display="row" items spacing={3}>
            <Grid item sm={12} md={4}>
              <Card sx={{p:2, minHeight: 270}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={TimeSaving} alt="TimeSaving" width="80px"/></Box>
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Time-Saving</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Python is the most convenient language with easy-to-learn syntax and less scope of errors for rapid development.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={4}>
              <Card sx={{p:2, minHeight: 270}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={CostSaving} alt="CostSaving" width="80px"/></Box>
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Cost-Saving</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>It is an open-source programming language which means you are paying only for their talent and the resource - a clear advantage over other programming languages.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={4}>
              <Card sx={{p:2, minHeight: 270}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={Compatible} alt="Compatible" width="80px"/></Box>
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Compatible</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Python Based applications are compatible with any platform, macOS, Windows or Linux.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={4}>
              <Card sx={{p:2, minHeight: 270}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={HighlyEfficient} alt="HighlyEfficient" width="80px"/></Box>  
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Highly Efficient</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Crowned as one of the most dynamic programming languages with massive libraries and frameworks extremely hard to find in any other technology.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={4}>
              <Card sx={{p:2, minHeight: 270}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={Technology} alt="Technology" width="80px"/></Box>  
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>Technology Of The Future</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Python is here to remain as most of the truly online future-centric businesses rely entirely on Python.</Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={4}>
              <Card sx={{p:2, minHeight: 270}}>
                <Box sx={{textAlign:'center', p:2, minHeight: 90}}><img src={growth} alt="growth" width="80px"/></Box>  
                <Typography variant="h5" className="NavyColourText" component="h5" sx={{fontWeight:600, mb:2, textAlign:'center', textTransform:'uppercase'}}>General-Purpose Language</Typography>
                <Typography variant="body1" sx={{mb:2, textAlign:'center', fontWeight:500}}>Python Developers can employ various development approaches and logic to build a web solution without coding from scratch.</Typography>
              </Card>
            </Grid>
          </Grid>
      </Container>   
    </Box>

    

    </>
    
  )
}

export default WhyPython