import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import project from '../../../images/project.jpg';
import projectTwo from '../../../images/projectTwo.jpg';
import { Helmet } from 'react-helmet';
import { Api } from '../../../Api';
import Loader from "react-js-loader";



class CaseStudy extends React.Component {

  constructor(props) {
    super(props);

      this.state = {
          caseStudySection: {},
          caseStudies: {},
          DataisLoaded: false,
      };

      window.scrollTo({
          top: 0,
          behavior: 'instant',
      });
  }

  componentDidMount = () => {
    fetch(Api.caseStudiesList)
      .then((res) => res.json())
      .then((json) => {

          console.log(json);
          this.setState({
            caseStudySection: json.case_study_section,
            caseStudies: json.case_studies_list,
              DataisLoaded: true
          });

      })        
  }

render() {
  const { DataisLoaded, caseStudySection, caseStudies} = this.state;
  const list = []
  
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  }

  const items = [];
  for (var i = 0; i < caseStudies.length; i++) {
    items.push(
      <Box className="item" data-value="1" sx={{p:1}}>
        <Box className="casestudyImage"><a href={`/case-studies/${caseStudies[i].slug}`}><img alt={caseStudies[i].title} width="100%" src={Api.siteUrl+"images/case-studies/"+caseStudies[i].portfolio_image} /></a></Box>
        <Box className="casestudyDes" sx={{p:2}}>
            <Typography className="white" variant="h5" component="h5" sx={{mb:3, fontWeight:600}}><a className="white" href="/casestudies/megasearch">{caseStudies[i].title}</a></Typography>
            <div dangerouslySetInnerHTML={{ __html:caseStudies[i].technologies}} />
        </Box>
      </Box>
    );
  }

  if (!DataisLoaded) {return <div className={"item"}>
      <Loader type="spinner-circle" bgColor={"rgb(30, 62, 91)"} title={"Loading..."} color={'rgb(30, 62, 91)'} size={100} />
      </div>;}
  else{
    return (
      <>
      <Helmet>
          <link href="https://python.dotsquares.com/casestudies" rel="canonical"/>
          <title>{caseStudySection.title}</title>
          <meta name="description" content={caseStudySection.description} />
      </Helmet>
      
      <Box className="NavyColour" sx={{ py:7 }}>
          <Container maxWidth="md">
              <Typography className="white" variant="h4" component="h4" sx={{mb:3, textAlign:'center', textTransform:'uppercase'}}>Case <strong>Studies</strong></Typography>
              <div class="bar"></div>
              <Typography className="white" variant="body1" sx={{mb:5, textAlign:'center'}}>{caseStudySection.description}</Typography>
          </Container>    
          
          <AliceCarousel
              mouseTracking
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              autoPlay="true"
              infinite="true"
              animationDuration="4000"
          /> 
      </Box>
      </>
    )
  }
  }
}

export default CaseStudy